import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import "shared"
import "helpers"
import "dialog-polyfill/dist/dialog-polyfill.css"

require("@rails/ujs").start()
require("turbolinks").start()

const application = Application.start()
const sharedContext = require.context("controllers/shared", true, /_controller\.js$/)
const landingPageContext = require.context("controllers/landing_page", true, /_controller\.js$/)

application.load(definitionsFromContext(sharedContext))
application.load(definitionsFromContext(landingPageContext))

global.application = application
global.state = {
  flashMessages: []
}
