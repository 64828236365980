import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "decodedEmail" ]
  static values = {
    encodedEmail: String,
    subject: String
  }

  connect() {
    this.decodeEmail()
  }

  decodeEmail() {
    this.decodedEmailTarget.href = this.href()
  }

  href() {
    if (this.hasSubjectValue) {
      return "mailto:" + this.email() + "?subject=" + this.subjectValue
    } else {
      return "mailto:" + this.email()
    }
  }

  email() {
    var plainEmail = ""
    var scrambledEmail = this.scrambledEmail()

    for (var i = 0; i < scrambledEmail.length; i++) {
      plainEmail += String.fromCharCode(scrambledEmail[i])
    }

    return plainEmail
  }

  scrambledEmail() {
    return JSON.parse(this.encodedEmailValue)
  }
}
