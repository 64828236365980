import { Controller } from "stimulus"

export default class extends Controller {
  onScroll() {
    const winHeight = document.documentElement.scrollTop || document.body.scrollTop
    const hero = document.getElementById("hero")
    const menuMobile = document.getElementById("menu-mobile")

    if (winHeight > 70) {
      this.element.classList.add("header__top-banner--hide")
      hero.classList.remove("hero--with-top-banner")
      menuMobile.classList.remove("menu-mobile--with-top-banner")
    } else {
      this.element.classList.remove("header__top-banner--hide")
      hero.classList.add("hero--with-top-banner")
      menuMobile.classList.add("menu-mobile--with-top-banner")
    }
  }
}
