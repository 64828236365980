import { Controller } from "stimulus"

function pushMarketingEvents() {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      "event": "Landed in admin"
    })
  }

  if (typeof fbq !== "undefined") {
    window.fbq("track", "StartTrial")
    window.fbq("trackCustom", "Landed in admin")
  }

  if (typeof ttq !== "undefined") {
    window.ttq.track("CompleteRegistration")
  }
}

function runPushMarketingEventsOnce() {
  const eventKey = "landedInAdminMarketingEventPushed"

  if (!sessionStorage.getItem(eventKey)) {
    pushMarketingEvents()
    sessionStorage.setItem(eventKey, "true")
  }
}

export default class extends Controller {
  static targets = ["submit"]
  static values = {
    clickOnTimeout: Boolean,
    onboardingFinished: Boolean,
    showLoadingIndicator: Boolean
  }

  connect() {
    if (this.onboardingFinishedValue) {
      runPushMarketingEventsOnce()
    }

    if (this.showLoadingIndicatorValue) {
      this.startLoadingIndicator()
    }

    if (this.clickOnTimeoutValue && this.submitTarget) {
      setTimeout(() => {
        this.submitTarget.click()
      }, 2000)
    }
  }

  submit() {
    setTimeout(() => this.submitTarget.click(), 1000)
  }

  startLoadingIndicator() {
    this.loadingIndicator.style.display = "block"
  }

  get loadingIndicator() {
    return this.element.querySelector("#loading-indicator")
  }
}
